// work data

export const Work = [
  {
    id: 1,
    name: "Agency Landing Page",
    description:
      "It is build on top of the React JS, with styledComponents and GSAP for smooth scrolling animations.",
    tags: ["react", "gsap", "styledComponents"],

    demo: "https://sample-agency-website.vercel.app/",
    github: "https://github.com/akash-shrestha/sample-agency-website",
  },
  {
    id: 2,
    name: "Deal Swipe",
    description:
      "A web app with tinder like effect for swiping best deals on shopping",
    tags: ["react", "react-spring, @use-gesture"],

    demo: "https://deal-swipe-pwa.vercel.app/",
    github: "https://github.com/akash-shrestha/deal-swipe-PWA/",
  },
  {
    id: 3,
    name: "Weather Mate ",
    description: "Its a weather app with pretty UI and easy UX",
    tags: ["react", "api", "gradient"],

    demo: "https://weather-mate-ten.vercel.app/",
    github: "https://github.com/akash-shrestha/weather-mate",
  },
  {
    id: 4,
    name: "Reactive Menu",
    description:
      "A cool looking sidebar menu that reacts to the menu selection.It has really smooth animations.",
    tags: ["react", "styledComponents", "react-router"],

    demo: "https://reactive-sidebar.vercel.app/",
    github: "https://github.com/akash-shrestha/reactive-sidebar",
  },
  {
    id: 5,
    name: "Animated todo",
    description: "A todo app build with smooth animations.",
    tags: ["react", "redux", "framer-motion"],

    demo: "https://animated-todo-alpha.vercel.app/",
    github: "https://github.com/akash-shrestha/animated-todo",
  },
  {
    id: 6,
    name: "Gift Crypto",
    description:
      "Web3 app that empowers users to effortlessly gift cryptocurrency paired with personalized messages and animated gif images for a unique and memorable experience",
    tags: ["react", "ethers", "solidity", "hardhat"],

    demo: "https://krypt-gift-crypto.vercel.app/",
    github: "https://github.com/akash-shrestha/crypto-gift-app/tree/main",
  },
  {
    id: 7,
    name: "Send Token",
    description:
      "A web3 app where users can seamlessly transfer tokens to desired accounts",
    tags: ["react", "ethers", "solidity", "hardhat"],

    demo: "https://token-transfer-app.vercel.app/",
    github: "https://github.com/akash-shrestha/token-transfer-app",
  },
  {
    id: 8,
    name: "Vivid Chess",
    description: "Vivid chess app where user can play against AI opponent",
    tags: ["react", "ML", "js-chess-engine"],

    demo: "https://vivid-chess.vercel.app/",
    github: "https://github.com/akash-shrestha/vivid-chess",
  },
  {
    id: 9,
    name: "Search component",
    description: "An elegant search component for web apps",
    tags: ["react", "search"],

    demo: "https://react-search-component-eight.vercel.app/",
    github: "https://github.com/akash-shrestha/react-search-component",
  },
];
