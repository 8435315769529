import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

const blink = keyframes`
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
`;

const ScrollIndicatorText = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.7; /* Adjust opacity for better visibility */
  font-size: 20px;
  color: ${(props) => props.theme.text}; /* Use theme for color consistency */
  transition: opacity 0.3s ease-in-out; /* Add smooth opacity transition */
  animation: ${blink} 3s ease-in-out infinite;
`;

const ScrollIndicator = () => {
  const [isVisible, setIsVisible] = React.useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      if (scrollY >= documentHeight - windowHeight - 100) {
        // Adjust threshold for better detection
        setIsVisible(false); // Hide indicator when near bottom
      } else {
        setIsVisible(true); // Show indicator otherwise
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div>
      {isVisible && (
        <ScrollIndicatorText>Please Scroll &#8595;</ScrollIndicatorText>
      )}
    </div>
  );
};

export default ScrollIndicator;
