import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { DarkTheme } from "../components/Themes";

const Logo = styled.h1`
  display: inline-block;
  color: ${(props) =>
    props.color === "dark" ? DarkTheme.text : DarkTheme.body};
  font-family: "Pacifico", cursive;

  position: fixed;
  left: 2rem;
  top: 2rem;
  z-index: 3;
  &:hover {
    transform: scale(1.1);
    transition: 1s ease;
  }
`;

const LogoComponent = (props) => {
  return (
    <Link to="https://akash-shrestha.github.io/">
      <Logo color={props.theme}>AS</Logo>
    </Link>
  );
};

export default LogoComponent;
