// blog data

export const Blogs = [
  {
    id: 1,
    name: "Hiboo",
    tags: [],
    date: "Hiboo is a software that collects data from all your heavy equipment and utility vehicles in one place — enhancing your productivity and energy efficiency seamlessly.",
    imgSrc:
      "https://res.cloudinary.com/dmphgxgck/image/upload/t_portfolio%20site/v1720069208/Screenshot_from_2024-06-23_15-18-23_amworq.png",
    link: "https://www.hiboo.io/",
  },
  {
    id: 2,
    name: "Playbook",
    tags: [],
    date: "Playbook is the most comprehensive and affordable virtual jazz music program on the market, and makes learning jazz an accessible opportunity for students",
    imgSrc:
      "https://res.cloudinary.com/dmphgxgck/image/upload/t_portfolio%20site/v1720069210/Screenshot_from_2024-06-23_15-16-59_tckabt.png",
    link: "https://www.thisisplaybook.com/",
  },
  {
    id: 3,
    name: "Ariel Finance",
    tags: [],
    date: "Ariel Finance offers seamless trading of cypto assets with leverage upto 25x",
    imgSrc:
      "https://res.cloudinary.com/dmphgxgck/image/upload/t_portfolio%20site/v1720069211/Screenshot_from_2024-06-23_15-38-41_ib7c65.png",
    link: "https://www.arielfinance.io/",
  },
  {
    id: 4,
    name: "MovePad",
    tags: [],
    date: "MovePad offers platform and services to lunch your ICO",
    imgSrc:
      "https://res.cloudinary.com/dmphgxgck/image/upload/t_portfolio%20site/v1720069215/Screenshot_from_2024-06-23_15-33-27_efrzsz.png",
    link: "https://www.movepad.io/",
  },
  {
    id: 5,
    name: "Yolo",
    tags: [],
    date: "Yoloswap provides user the ability swap cypto assets instantly at fraction of fee",
    imgSrc:
      "https://res.cloudinary.com/dmphgxgck/image/upload/t_portfolio%20site/v1720069212/Screenshot_from_2024-06-23_16-15-47_bzqgjn.png",
    link: "https://yoloswap.com/",
  },
  {
    id: 6,
    name: "Mondou",
    tags: [],
    date: "Shop all the needs for your pet at one stop",
    imgSrc:
      "https://res.cloudinary.com/dmphgxgck/image/upload/t_portfolio%20site/v1720069216/Screenshot_from_2024-06-23_17-06-26_kjrmdh.png",
    link: "https://www.mondou.com/",
  },
  {
    id: 7,
    name: "Suiheroes",
    tags: [],
    date: "Online casino games with crypto assets",
    imgSrc:
      "https://res.cloudinary.com/dmphgxgck/image/upload/t_portfolio%20site/v1720069215/Screenshot_from_2024-06-23_16-28-42_kiiofp.png",
    link: "https://suiheroes.com/",
  },
  {
    id: 8,
    name: "Lumanagi",
    tags: [],
    date: "A digital asset exchange hub with continuously increasing gold-backing",
    imgSrc:
      "https://res.cloudinary.com/dmphgxgck/image/upload/t_portfolio%20site/v1720069215/Screenshot_from_2024-06-23_16-39-57_yhythe.png",
    link: "https://lumanagi.com/",
  },
];
