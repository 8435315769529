import React, { useRef, useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

import music from "../assets/audio/u-said-it-v13-1167.mp3";

const Box = styled.div`
  display: flex;
  cursor: pointer;
  position: fixed;
  left: 8rem;
  top: 3rem;
  z-index: 10;
  &:hover {
    transform: scale(1.1);
  }

  & > *:nth-child(1) {
    animation-delay: 0.2s;
  }
  & > *:nth-child(2) {
    animation-delay: 0.3s;
  }
  & > *:nth-child(3) {
    animation-delay: 0.4s;
  }
  & > *:nth-child(4) {
    animation-delay: 0.5s;
  }
  & > *:nth-child(5) {
    animation-delay: 0.8s;
  }
`;

const play = keyframes`
  0% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(2);
  }
  100% {
    transform: scaleY(1);
  }
`;

const Line = styled.span`
  background: ${(props) => props.theme.text};
  border: 1px solid ${(props) => props.theme.body};

  animation: ${play} 1s ease infinite;
  animation-play-state: ${(props) => (props.click ? "running" : "paused")};
  height: 1rem;
  width: 2px;
  margin: 0 0.1rem;
`;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1rem;
  border: 1px solid #ddd;
  z-index: 11; // Higher than SoundBar for overlay
  border-radius: 0 1rem 1rem 1rem;
  font-family: "Pacifico", cursive;
  line-height: 2rem;
`;

const Button = styled.button`
  backdrop-filter: blur(5px); /* Add blur effect */
  color: rgba(255, 255, 255, 0.9);
  background: linear-gradient(-45deg, #ffa63d, #ff3d77, #338aff, #3cf0c5);
  background-size: 600%;

  animation: fadeIn 2s ease-in-out forwards, anime 12s linear infinite;
  -webkit-animation: fadeIn 2s ease-in-out forwards, anime 12s linear infinite;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes anime {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 50px;
  transition: 0.5s ease-in-out; /* Add hover effect */
  &:hover {
    box-shadow: 0 0 8px 6px rgb(46 46 46 / 20%);
    transform: scale(1.1);
  }

  & > *:first-child {
    text-decoration: none;
    color: inherit;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
`;

const SoundBar = () => {
  const ref = useRef(null);
  const [click, setClick] = useState(false);
  const [showPopup, setShowPopup] = useState(true); // Added state for popup display

  const handleClick = () => {
    setClick(!click);

    if (!click) {
      ref.current.play();
    } else {
      ref.current.pause();
    }
  };

  const handlePlay = () => {
    setShowPopup(false); // Hide popup on play selection
    handleClick(); // Play audio
  };

  const handleMute = () => {
    setShowPopup(false); // Hide popup on mute selection
  };

  return (
    <>
      {showPopup && ( // Render popup only if showPopup is true
        <Popup>
          <p>🙂Hello, Would you like some background music 🎵 ?</p>
          <ButtonContainer>
            <Button onClick={handlePlay}>Sure</Button>
            <Button onClick={handleMute}>Later</Button>
          </ButtonContainer>
        </Popup>
      )}
      <Box onClick={handleClick}>
        <Line click={click} />
        <Line click={click} />
        <Line click={click} />
        <Line click={click} />
        <Line click={click} />

        <audio src={music} ref={ref} loop />
      </Box>
    </>
  );
};

export default SoundBar;
